<template>
  <div v-if="can && !loading">
    <b-card no-body>
      <b-card-header>
        <h3 class="mb-0">
          General metrics
        </h3>
      </b-card-header>

      <b-row class="text-center mx-0">
        <b-col
          v-for="(metric, key) in generalMetrics"
          :key="key"
          :cols="12 / generalMetrics.length"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="mb-0">
            {{ metric.label }}
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ metric.value }}
          </h3>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col
        v-for="(market, key) in marketItems"
        :key="key"
        cols="12"
        md="4"
        class="mb-2"
      >
        <vx-table-simple
          :items="[market]"
          :columns="marketColumns"
          stacked
        >
          <template #title>
            {{ market.title }}
          </template>
        </vx-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardText, BCol, BRow } from 'bootstrap-vue'
import { metrics, useResource } from '@/services/resources'
import { ref } from '@vue/composition-api'
import VxTableSimple from '@/components/table/vx-table-simple/VxTableSimple'
import { VxCellMoney } from '@/components/table'
import { buildMoney, labelFormat, moneyFormat } from '@/services/utils'

export default {
  name: 'Home',
  components: {
    VxTableSimple,
    VxCellMoney,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText
  },
  setup () {
    const { can, loading, callRequest } = useResource(metrics.getGeneral)

    const marketItems = ref([])
    const generalMetrics = ref([])
    const metricsMapper = {
      daily_new_player_count: {
        label: 'Daily new players'
      },
      daily_skin_withdrawal_sum: {
        label: 'Daily skin withdrawal',
        formatter: moneyFormat
      },
      daily_successful_refill_sum: {
        label: 'Daily successful refill',
        formatter: moneyFormat
      }
    }

    const getGeneralMetrics = async () => {
      if (!can) return

      const [err, res] = await callRequest()
      if (err) return

      const { data: { markets, ...restMetrics } } = res

      marketItems.value = markets

      // format metrics into human-readable headers
      generalMetrics.value = Object.keys(restMetrics).map((key) => {
        const { label, formatter } = metricsMapper[key]
        const value = restMetrics[key]

        return {
          label: label || labelFormat(key),
          value: formatter ? formatter(value) : value
        }
      })
    }

    const marketColumns = [
      {
        key: 'bot_balance',
        component: VxCellMoney,
        tdAttr: (botBalance, key, item) => ({
          money: buildMoney(botBalance, item?.currency_code)
        })
      },
      {
        key: 'available_skin_amount',
        label: 'Available items total'
      },
      {
        key: 'unique_skin_count',
        label: 'Available items unique'
      }
    ]

    getGeneralMetrics()

    return {
      can,
      loading,

      marketColumns,
      marketItems,
      generalMetrics
    }
  }
}
</script>
